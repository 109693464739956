import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import orders from "./orders/reducer";
import transactions from "./transactions/reducer";
import home from "./home/reducer";
import bankAccounts from "./bankAccounts/reducer";

const rootReducer = combineReducers({
  authentication,
  orders,
  transactions,
  home,
  bankAccounts,
});

export default rootReducer;
