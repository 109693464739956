import {
  GET_ALL_BANK_ACCOUNTS,
  GET_ALL_BANK_ACCOUNTS_SUCCESS,
  GET_ALL_BANK_ACCOUNTS_FAILURE,
  GET_SINGLE_BANK_ACCOUNT,
  GET_SINGLE_BANK_ACCOUNT_SUCCESS,
  GET_SINGLE_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAILURE,
  GET_ALL_BANKS,
  GET_ALL_BANKS_SUCCESS,
  GET_ALL_BANKS_FAILURE,
  SET_BANK_ACCOUNT_AS_DEFAULT,
  SET_BANK_ACCOUNT_AS_DEFAULT_SUCCESS,
  SET_BANK_ACCOUNT_AS_DEFAULT_FAILURE,
} from "./actionTypes";

const initialState = {
  bankAccounts: [],
  banks: [],
  metaData: {},
  singleBankAccount: {},
  loading: false,
  error: "",
};

const bankAccounts = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_BANK_ACCOUNTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_BANK_ACCOUNTS_SUCCESS: {
      const { bankAccounts, metaData } = payload || {};
      state = {
        ...state,
        loading: false,
        bankAccounts: bankAccounts,
        metaData: metaData,
      };
      break;
    }
    case GET_ALL_BANK_ACCOUNTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_BANK_ACCOUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_BANK_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleBankAccount: payload.bankAccount,
      };
      break;
    case GET_SINGLE_BANK_ACCOUNT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleBankAccount: {},
      };
      break;

    case ADD_BANK_ACCOUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_BANK_ACCOUNT_SUCCESS:
      const bankAccountsArrayAfterAddingBankAccount = [
        ...state?.bankAccounts,
        payload.bankAccount,
      ];
      state = {
        ...state,
        loading: false,
        bankAccounts: bankAccountsArrayAfterAddingBankAccount,
        error: "",
      };
      break;
    case ADD_BANK_ACCOUNT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_BANK_ACCOUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_BANK_ACCOUNT_SUCCESS:
      const bankAccountsArrayAfterDeletingBankAccount = [
        ...state?.bankAccounts?.filter(
          (bankAccount) => bankAccount?._id !== payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        bankAccounts: bankAccountsArrayAfterDeletingBankAccount,
      };
      break;
    case DELETE_BANK_ACCOUNT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    /// banks

    case GET_ALL_BANKS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_BANKS_SUCCESS: {
      const { banks } = payload || {};
      state = {
        ...state,
        loading: false,
        banks: banks,
      };
      break;
    }
    case GET_ALL_BANKS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    /// setBankAccount is Default

    // TODO COMPLETE THE SET BANK ACCOUNT FEATURE
    case SET_BANK_ACCOUNT_AS_DEFAULT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_BANK_ACCOUNT_AS_DEFAULT_SUCCESS: {
      const { banks } = payload || {};
      state = {
        ...state,
        loading: false,
        banks: banks,
      };
      break;
    }
    case SET_BANK_ACCOUNT_AS_DEFAULT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default bankAccounts;
