import { lazy } from "react";
const appRoutes = [
  {
    path: "/",
    Element: lazy(() => import("views/dashboard")),
  },
  {
    path: "/dashboard",
    Element: lazy(() => import("views/dashboard")),
  },
  {
    path: "/orders",
    Element: lazy(() => import("views/orders/Index")),
  },
  {
    path: "/orders",
    Element: lazy(() => import("views/orders/Index")),
  },
  {
    path: "/orders/:orderId",
    Element: lazy(() => import("views/orders/ShowOrder")),
  },
  {
    path: "/transactions",
    Element: lazy(() => import("views/transactions/Index")),
  },
  {
    path: "/transactions/:id",
    Element: lazy(() => import("views/transactions/View")),
  },
  {
    path: "/bankAccounts",
    Element: lazy(() => import("views/bankAccounts/Index")),
  },
  {
    path: "/bankAccounts/addBankAccount",
    Element: lazy(() => import("views/bankAccounts/AddBankAccount")),
  },
  {
    path: "/bankAccounts/show/:bankAccountId",
    Element: lazy(() => import("views/bankAccounts/ShowBankAccount")),
  },
  {
    path: "/bankAccounts/:bankAccountId",
    Element: lazy(() => import("views/bankAccounts/EditBankAccount")),
  },
  {
    path: "/subscriberStatus",
    Element: lazy(() => import("views/subscriberStatus/SubscriberStatus")),
  },
];

export default appRoutes;
