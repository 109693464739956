import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import TransactionsSaga from "./transactions/saga";
import ordersSaga from "./orders/saga";
import ToasterSaga from "./toastify/saga";
import bankAccountsSaga from "./bankAccounts/saga";
import homeSaga from "./home/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    TransactionsSaga(),
    ordersSaga(),
    homeSaga(),
    bankAccountsSaga(),
    ToasterSaga(),
  ]);
}
