import { useState, useEffect } from "react";
import axios from "axios";

// Hook
function useGetLogoImage() {
  const [logoSrc, setLogoSrc] = useState("");

  useEffect(() => {
    const getLogoImage = async () => {
      const headers = {};

      const token = localStorage.getItem("token");
      if (token) headers.Authorization = `Bearer ${token}`;
      const response = await axios.get(
        `https://delivery.shayek.sa/api/settings/main`,
        { headers }
      );
      const { settings } = response?.data?.data;
      const { value } = settings?.find((obj) => obj?.title === "appLogo") || {};
      setLogoSrc(value);
    };
    getLogoImage();
  }, []);
  return logoSrc;
}

export default useGetLogoImage;
