import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addTransactionApi,
  deleteTransactionApi,
  editTransactionApi,
  getAllTransactionsApi,
  getSingleTransactionApi,
} from "../../api/transactions";

// Login Redux States
import {
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
  EDIT_TRANSACTION,
  GET_ALL_TRANSACTIONS,
  GET_SINGLE_TRANSACTION,
} from "./actionTypes";
import {
  getAllTransactionsSuccess,
  getAllTransactionsFailure,
  getSingleTransactionSuccess,
  getSingleTransactionFailure,
  deleteTransactionSuccess,
  deleteTransactionFailure,
  editTransactionSuccess,
  editTransactionFailure,
  addTransactionSuccess,
  addTransactionFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

function* getAllTransactions({ payload }) {
  try {
    const { data } = yield call(getAllTransactionsApi, payload);
    yield put(getAllTransactionsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllTransactionsFailure(error.response.data || error));
  }
}

function* getSingleTransaction({ payload }) {
  try {
    const { data } = yield call(getSingleTransactionApi, payload);
    yield put(getSingleTransactionSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleTransactionFailure(error.response.data || error));
  }
}

function* addTransaction({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addTransactionApi, payload);
    yield put(addTransactionSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(addTransactionFailure(error.response.data.message || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* deleteTransaction({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteTransactionApi, payload);
    yield put(deleteTransactionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteTransactionFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* editTransaction({ payload }) {
  const id = Math.random();

  try {
    yield call(editTransactionApi, payload);
    yield put(editTransactionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(editTransactionFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

export function* watchGetAllTransactions() {
  yield takeEvery(GET_ALL_TRANSACTIONS, getAllTransactions);
}
export function* watchGetSingleTransaction() {
  yield takeEvery(GET_SINGLE_TRANSACTION, getSingleTransaction);
}
export function* watchDeleteTransaction() {
  yield takeEvery(DELETE_TRANSACTION, deleteTransaction);
}
export function* watchAddTransaction() {
  yield takeEvery(ADD_TRANSACTION, addTransaction);
}
export function* watchEditTransaction() {
  yield takeEvery(EDIT_TRANSACTION, editTransaction);
}

function* transactionsSaga() {
  yield all([fork(watchGetAllTransactions)]);
  yield all([fork(watchAddTransaction)]);
  yield all([fork(watchEditTransaction)]);
  yield all([fork(watchDeleteTransaction)]);
  yield all([fork(watchGetSingleTransaction)]);
}

export default transactionsSaga;
