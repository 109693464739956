import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  GET_USER_STATUS,
  GET_USER_STATUS_SUCCESS,
  GET_USER_STATUS_FAILURE,
} from "./actionTypes";

export const loginUser = (payload) => {
  return {
    type: LOGIN_USER,
    payload,
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};

export const getCurrentUser = () => {
  return {
    type: CURRENT_USER,
  };
};

export const getCurrentUserSuccess = (payload) => {
  return {
    type: CURRENT_USER_SUCCESS,
    payload,
  };
};

export const getCurrentUserFailure = (payload) => {
  return {
    type: CURRENT_USER_FAILURE,
    payload,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const getUserStatus = (payload) => {
  return {
    type: GET_USER_STATUS,
    payload,
  };
};
export const getUserStatusSuccess = (url) => {
  return {
    type: GET_USER_STATUS_SUCCESS,
    payload: url,
  };
};

export const getUserStatusFailure = (error) => {
  return {
    type: GET_USER_STATUS_FAILURE,
    payload: error,
  };
};
export const updateUserStatus = (payload) => {
  return {
    type: UPDATE_USER_STATUS,
    payload,
  };
};
export const updateUserStatusSuccess = (url) => {
  return {
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: url,
  };
};

export const updateUserStatusFailure = (error) => {
  return {
    type: UPDATE_USER_STATUS_FAILURE,
    payload: error,
  };
};
