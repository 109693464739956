import {
	LOAD_OR_UPDATE_TOASTER,
	NOTIFY_TOASTER,
	CREATE_TOASTER,
} from "./actionTypes";

export const createToaster = payload => ({
	type: CREATE_TOASTER,
	payload,
});

export const createLoaderUpdaterToaster = payload => ({
	type: LOAD_OR_UPDATE_TOASTER,
	payload,
});

export const createNotifyToaster = payload => ({
	type: NOTIFY_TOASTER,
	payload,
});
