import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = (props) => {
  const sidebarToggle = (e) => {
    if (window.innerWidth > 1024 && !e.target.closest(".toggleSidebar")) return;

    document.querySelector("html").classList.toggle("offScroll");
    document.querySelector(".overlay-s").classList.toggle("fire");
    document.querySelector("body").classList.toggle("sidebarToggled");
  };
  return (
    <div className="main-wrapper">
      <Header sidebarToggle={sidebarToggle} />
      <Sidebar sidebarToggle={sidebarToggle} />
      <div className="content-wrap" id="content-wrapper">
        {props.children}
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
