import server from "./server";

export const getAllTransactionsApi = async (payload) => {
  const response = await server().get(`/transactions${payload}`);
  return response.data;
};

export const getSingleTransactionApi = async (id) => {
  const response = await server().get(`/transactions/${id}`);
  return response.data;
};

export const addTransactionApi = async (payload) => {
  const response = await server().post(`/transactions`, payload);
  return response.data;
};
export const deleteTransactionApi = async (id) => {
  const response = await server().delete(`/transactions/${id}`);
  return response.data;
};

export const editTransactionApi = async ({ id, data }) => {
  const response = await server().put(`/transactions/${id}`, data);
  return response.data;
};
