import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addOrderApi,
  deleteOrderApi,
  editOrderApi,
  getAllOrdersApi,
  getSingleOrderApi,
} from "api/orders";

// Login Redux States
import {
  ADD_ORDER,
  DELETE_ORDER,
  EDIT_ORDER,
  GET_ALL_ORDERS,
  GET_SINGLE_ORDER,
} from "./actionTypes";
import {
  getAllOrdersSuccess,
  getAllOrdersFailure,
  getSingleOrderSuccess,
  getSingleOrderFailure,
  deleteOrderSuccess,
  deleteOrderFailure,
  editOrderSuccess,
  editOrderFailure,
  addOrderSuccess,
  addOrderFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

function* getAllOrders({ payload }) {
  try {
    const { data } = yield call(getAllOrdersApi, payload);
    yield put(getAllOrdersSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllOrdersFailure(error.response.data || error));
  }
}

function* getSingleOrder({ payload }) {
  try {
    const { data } = yield call(getSingleOrderApi, payload);
    yield put(getSingleOrderSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleOrderFailure(error.response.data || error));
  }
}

function* addOrder({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addOrderApi, payload);
    yield put(addOrderSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(addOrderFailure(error.response.data.message || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* deleteOrder({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteOrderApi, payload);
    yield put(deleteOrderSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteOrderFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

function* editOrder({ payload }) {
  const id = Math.random();

  try {
    yield call(editOrderApi, payload);
    yield put(editOrderSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(editOrderFailure(error.response.data || error));
    yield put(
      createToaster({ type: "error", message: "error", toasterID: id })
    );
  }
}

export function* watchGetAllOrders() {
  yield takeEvery(GET_ALL_ORDERS, getAllOrders);
}
export function* watchGetSingleOrder() {
  yield takeEvery(GET_SINGLE_ORDER, getSingleOrder);
}
export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrder);
}
export function* watchAddOrder() {
  yield takeEvery(ADD_ORDER, addOrder);
}
export function* watchEditOrder() {
  yield takeEvery(EDIT_ORDER, editOrder);
}

function* ordersSaga() {
  yield all([fork(watchGetAllOrders)]);
  yield all([fork(watchAddOrder)]);
  yield all([fork(watchEditOrder)]);
  yield all([fork(watchDeleteOrder)]);
  yield all([fork(watchGetSingleOrder)]);
}

export default ordersSaga;
