export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";
export const GET_ALL_TRANSACTIONS_FAILURE = "GET_ALL_TRANSACTIONS_FAILURE";

export const GET_SINGLE_TRANSACTION = "GET_SINGLE_TRANSACTION";
export const GET_SINGLE_TRANSACTION_SUCCESS = "GET_SINGLE_TRANSACTION_SUCCESS";
export const GET_SINGLE_TRANSACTION_FAILURE = "GET_SINGLE_TRANSACTION_FAILURE";

export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";
export const ADD_TRANSACTION_FAILURE = "ADD_TRANSACTION_FAILURE";

export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const EDIT_TRANSACTION_SUCCESS = "EDIT_TRANSACTION_SUCCESS";
export const EDIT_TRANSACTION_FAILURE = "EDIT_TRANSACTION_FAILURE";

export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_FAILURE = "DELETE_TRANSACTION_FAILURE";
