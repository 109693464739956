import {
  GET_ALL_BANK_ACCOUNTS,
  GET_ALL_BANK_ACCOUNTS_SUCCESS,
  GET_ALL_BANK_ACCOUNTS_FAILURE,
  DELETE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  GET_SINGLE_BANK_ACCOUNT,
  GET_SINGLE_BANK_ACCOUNT_SUCCESS,
  GET_SINGLE_BANK_ACCOUNT_FAILURE,
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAILURE,
  EDIT_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT_SUCCESS,
  EDIT_BANK_ACCOUNT_FAILURE,
  GET_ALL_BANKS,
  GET_ALL_BANKS_SUCCESS,
  GET_ALL_BANKS_FAILURE,
  SET_BANK_ACCOUNT_AS_DEFAULT,
  SET_BANK_ACCOUNT_AS_DEFAULT_SUCCESS,
  SET_BANK_ACCOUNT_AS_DEFAULT_FAILURE,
} from "./actionTypes";

export const getAllBankAccounts = (search) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS,
    payload: search,
  };
};

export const getAllBankAccountsSuccess = (bankAccount) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS_SUCCESS,
    payload: bankAccount,
  };
};

export const getAllBankAccountsFailure = (error) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS_FAILURE,
    payload: error,
  };
};

export const getSingleBankAccount = (id) => {
  return {
    type: GET_SINGLE_BANK_ACCOUNT,
    payload: id,
  };
};

export const getSingleBankAccountSuccess = (bankAccount) => {
  return {
    type: GET_SINGLE_BANK_ACCOUNT_SUCCESS,
    payload: bankAccount,
  };
};

export const getSingleBankAccountFailure = (error) => {
  return {
    type: GET_SINGLE_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};

export const addBankAccount = (payload) => {
  return {
    type: ADD_BANK_ACCOUNT,
    payload,
  };
};

export const addBankAccountSuccess = (payload) => {
  return {
    type: ADD_BANK_ACCOUNT_SUCCESS,
    payload,
  };
};

export const addBankAccountFailure = (error) => {
  return {
    type: ADD_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};

export const editBankAccount = (payload) => {
  return {
    type: EDIT_BANK_ACCOUNT,
    payload,
  };
};

export const editBankAccountSuccess = (payload) => {
  return {
    type: EDIT_BANK_ACCOUNT_SUCCESS,
    payload,
  };
};

export const editBankAccountFailure = (error) => {
  return {
    type: EDIT_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};

export const deleteBankAccount = (id) => {
  return {
    type: DELETE_BANK_ACCOUNT,
    payload: id,
  };
};

export const deleteBankAccountSuccess = (id) => {
  return {
    type: DELETE_BANK_ACCOUNT_SUCCESS,
    payload: id,
  };
};

export const deleteBankAccountFailure = (error) => {
  return {
    type: DELETE_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
};

// banks

export const getAllBanks = (payload) => {
  return {
    type: GET_ALL_BANKS,
    payload,
  };
};

export const getAllBanksSuccess = (banks) => {
  return {
    type: GET_ALL_BANKS_SUCCESS,
    payload: banks,
  };
};

export const getAllBanksFailure = (error) => {
  return {
    type: GET_ALL_BANKS_FAILURE,
    payload: error,
  };
};

// SET BANK ACCOUNT IS DEFAULT

export const setBankAccountAsDefault = (payload) => {
  return {
    type: SET_BANK_ACCOUNT_AS_DEFAULT,
    payload,
  };
};

export const setBankAccountAsDefaultSuccess = (bankAccount) => {
  return {
    type: SET_BANK_ACCOUNT_AS_DEFAULT_SUCCESS,
    payload: bankAccount,
  };
};

export const setBankAccountAsDefaultFailure = (error) => {
  return {
    type: SET_BANK_ACCOUNT_AS_DEFAULT_FAILURE,
    payload: error,
  };
};
