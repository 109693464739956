import { toast } from "react-toastify";
// import { withCallback } from "redux-saga-callback";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import globalToaster from "../../helpers/toaster/toaster";
import { toasterTransitions } from "../../helpers/toaster/toasterTransitions";
import { createLoaderUpdaterToaster, createNotifyToaster } from "./actions";
import {
  CREATE_TOASTER,
  LOAD_OR_UPDATE_TOASTER,
  NOTIFY_TOASTER,
} from "./actionTypes";

const transition = toasterTransitions;

function* createToaster({ payload }) {
  let targetedToaster = payload.toasterID ?? globalToaster;
  if (toast.isActive(targetedToaster)) {
    yield put(
      createLoaderUpdaterToaster({ ...payload, toasterID: targetedToaster })
    );
  } else {
    yield put(createNotifyToaster({ ...payload, toasterID: targetedToaster }));
  }
}

function* updateLoadingToaster({ payload }) {
  switch (payload.type) {
    case "success":
      yield toast.update(payload.toasterID, {
        type: toast.TYPE.SUCCESS,
        render: payload.message,
        toastId: payload.toasterID,
        isLoading: false,
        closeOnClick: true,
        autoClose: 3000,
        theme: "colored",
        draggable: false,
        transition: transition,
      });
      break;

    case "error":
      yield toast.update(payload.toasterID, {
        type: toast.TYPE.ERROR,
        render: payload.message,
        toastId: payload.toasterID,
        isLoading: false,
        closeOnClick: true,
        autoClose: 3000,
        theme: "colored",
        draggable: false,
        transition: transition,
      });
      break;

    case "info":
      yield toast.update(payload.toasterID, {
        type: toast.TYPE.INFO,
        render: payload.message,
        toastId: payload.toasterID,
        isLoading: false,
        closeOnClick: true,
        autoClose: 3000,
        theme: "colored",
        draggable: false,
        transition: transition,
      });
      break;

    case "loading":
      yield toast.update({
        toastId: payload.toasterID,
        isLoading: true,
        render: payload.message,
        closeButton: false,
        closeOnClick: false,
        theme: "dark",
        draggable: false,
      });
      return;

    default:
      yield setTimeout(() => toast.dismiss(payload.toasterID), 3000);
      return;
  }
}

function* notifyToaster({ payload }) {
  switch (payload.type) {
    case "success":
      yield toast(payload.message, {
        type: toast.TYPE.SUCCESS,
        toastId: payload.toasterID,
        isLoading: false,
        closeOnClick: true,
        autoClose: 3000,
        theme: "colored",
        className: "rotateY animated",
        draggable: false,
      });
      break;

    case "error":
      yield toast(payload.message, {
        type: toast.TYPE.ERROR,
        toastId: payload.toasterID,
        isLoading: false,
        closeOnClick: true,
        autoClose: 3000,
        theme: "colored",
        className: "rotateY animated",
        draggable: false,
      });
      break;

    case "info":
      yield toast(payload.message, {
        type: toast.TYPE.INFO,
        toastId: payload.toasterID,
        isLoading: false,
        closeOnClick: true,
        autoClose: 3000,
        theme: "colored",
        className: "rotateY animated",
        draggable: false,
      });
      break;
    case "warn" || "warning":
      yield toast(payload.message, {
        type: toast.TYPE.warn,
        toastId: payload.toasterID,
        isLoading: false,
        closeOnClick: true,
        autoClose: 3000,
        theme: "colored",
        className: "rotateY animated",
        draggable: false,
      });
      break;
    case "loading":
      yield toast(payload.message, {
        toastId: payload.toasterID,
        isLoading: true,
        closeButton: false,
        closeOnClick: false,
        theme: "dark",
        className: "rotateY animated",
        draggable: false,
      });
      break;

    default:
      yield setTimeout(() => toast.dismiss(payload.toasterID), 3000);
      return;
  }
}

export function* watchLoaderToaster() {
  yield takeEvery(LOAD_OR_UPDATE_TOASTER, updateLoadingToaster);
}

export function* watchNotifyToaster() {
  yield takeEvery(NOTIFY_TOASTER, notifyToaster);
}

export function* watchCreateToaster() {
  yield takeEvery(CREATE_TOASTER, createToaster);
}

function* ToasterSaga() {
  yield all([fork(watchCreateToaster)]);
  yield all([fork(watchLoaderToaster)]);
  yield all([fork(watchNotifyToaster)]);
}

export default ToasterSaga;
