import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import Arabic from "./lang/ar.json";
import English from "./lang/en.json";
// import cookie from "js-cookie";
// import server from "../api/server";

const Wrapper = (props) => {
  const body = document.querySelector("body");
  // const getCookieLang = cookie.get("cp_lang");
  // const [locale, setLocale] = useState(getCookieLang ? getCookieLang : "ar");
  const [locale, setLocale] = useState("ar");
  const [messages, setMessages] = useState(Arabic);

  // const [messages, setMessages] = useState(
  // 	getCookieLang === "en" ? English : Arabic
  // );

  // if (getCookieLang === "en") {
  // 	body.classList.add("english");
  // 	body.classList.remove("arabic");
  // } else {
  // 	body.classList.add("arabic");
  // 	body.classList.remove("english");
  // }
  // const selectLanguage = async e => {
  // 	const newLocale = e.target.value;
  // 	try {
  // 		const setLang = await server.post("/setLocale", {
  // 			lang: newLocale,
  // 		});

  // 		if (setLang.status === 200) {
  // 			cookie.set("cp_lang", newLocale, { expires: 90 });
  // 			window.location.reload();
  // 		}
  // 	} catch (error) {
  // 		console.log("Error");
  // 	}
  // 	// setLocale(newLocale);
  // 	// if (newLocale === 'en') {
  // 	// 	body.classList.add("english")
  // 	// 	body.classList.remove("arabic")
  // 	// 	setMessages(English);
  // 	// } else {
  // 	// 	body.classList.add("arabic")
  // 	// 	body.classList.remove("english")
  // 	// 	setMessages(Arabic);
  // 	// }
  // };

  return (
    // selectLanguage
    <Context.Provider value={{ locale }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export const Context = React.createContext();
export default Wrapper;
