import {
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILURE,
  GET_SINGLE_TRANSACTION,
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_FAILURE,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILURE,
  ADD_TRANSACTION,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILURE,
} from "./actionTypes";

const initialState = {
  transactions: [],
  singleTransaction: {},
  loading: false,
  error: "",
};

const transaction = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_TRANSACTIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_TRANSACTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        transactions: payload.transactions,
        metaData: payload.metadata,
      };
      break;
    case GET_ALL_TRANSACTIONS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_TRANSACTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_TRANSACTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleTransaction: payload.transaction,
      };
      break;
    case GET_SINGLE_TRANSACTION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleTransaction: {},
      };
      break;

    case ADD_TRANSACTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_TRANSACTION_SUCCESS:
      const transactionArrayAfterAddingTransaction = [
        ...state?.transactions,
        payload.transactions,
      ];
      state = {
        ...state,
        loading: false,
        transactions: transactionArrayAfterAddingTransaction,
        error: "",
      };
      break;
    case ADD_TRANSACTION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_TRANSACTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_TRANSACTION_SUCCESS:
      const transactionArrayAfterDeletingTransaction = [
        ...state?.transactions?.filter(
          (transaction) => transaction?.id !== payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        transactions: transactionArrayAfterDeletingTransaction,
      };
      break;
    case DELETE_TRANSACTION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default transaction;
