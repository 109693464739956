import AvatarPlaceHolder from "assets/images/user.png";
import NoImagePlaceHolder from "assets/images/no-image-placeholder.png";

export const handleImage = (image) => {
  if (image) {
    if (image.indexOf("http") < 0)
      image = `${process.env.REACT_MAIN_APP_URL}/${image}`;
    return image;
  }
  return image;
};
export const handlePrice = (price) => {
  if (price) {
    return `${parseInt(price)} ر.س`;
  }
  return "error";
};

export const checkIfUserHasPermission = (
  userPermissions = [],
  testedPermission
) => {
  if (userPermissions.length === 0) return false;
  const hasPermission = userPermissions.includes(testedPermission);
  return hasPermission;
};

export const handleSearchParamsChange = (search, params) => {
  const url = new URLSearchParams("");
  search = { ...search, ...params };
  Object.entries(search).forEach(([key, value]) => {
    url.set(key, value);
  });
  return "?" + url;
};

export const themeSwitcherFunction = () => {
  document.querySelector(".themetoggle").classList.toggle("fire");
  document.querySelector("body").classList.toggle("dark");
};

export const returnImageWithoutUrlPrefix = (fullImagePath) => {
  const uploadedImagePath = fullImagePath.split(
    `${process?.env?.REACT_APP_API_URL}/`
  )[1];
  return uploadedImagePath;
};

export const errorMessage = "هذا الحقل مطلوب";
