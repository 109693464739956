import {
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILURE,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILURE,
  GET_SINGLE_TRANSACTION,
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_FAILURE,
  ADD_TRANSACTION,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILURE,
  EDIT_TRANSACTION,
  EDIT_TRANSACTION_SUCCESS,
  EDIT_TRANSACTION_FAILURE,
} from "./actionTypes";

export const getAllTransactions = (searchParams) => {
  return {
    type: GET_ALL_TRANSACTIONS,
    payload: searchParams,
  };
};

export const getAllTransactionsSuccess = (transactions) => {
  return {
    type: GET_ALL_TRANSACTIONS_SUCCESS,
    payload: transactions,
  };
};

export const getAllTransactionsFailure = (error) => {
  return {
    type: GET_ALL_TRANSACTIONS_FAILURE,
    payload: error,
  };
};

export const getSingleTransaction = (id) => {
  return {
    type: GET_SINGLE_TRANSACTION,
    payload: id,
  };
};

export const getSingleTransactionSuccess = (driver) => {
  return {
    type: GET_SINGLE_TRANSACTION_SUCCESS,
    payload: driver,
  };
};

export const getSingleTransactionFailure = (error) => {
  return {
    type: GET_SINGLE_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const addTransaction = (payload) => {
  return {
    type: ADD_TRANSACTION,
    payload,
  };
};

export const addTransactionSuccess = (payload) => {
  return {
    type: ADD_TRANSACTION_SUCCESS,
    payload,
  };
};

export const addTransactionFailure = (error) => {
  return {
    type: ADD_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const editTransaction = (payload) => {
  return {
    type: EDIT_TRANSACTION,
    payload,
  };
};

export const editTransactionSuccess = (payload) => {
  return {
    type: EDIT_TRANSACTION_SUCCESS,
    payload,
  };
};

export const editTransactionFailure = (error) => {
  return {
    type: EDIT_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const deleteTransaction = (id) => {
  return {
    type: DELETE_TRANSACTION,
    payload: id,
  };
};

export const deleteTransactionSuccess = (id) => {
  return {
    type: DELETE_TRANSACTION_SUCCESS,
    payload: id,
  };
};

export const deleteTransactionFailure = (error) => {
  return {
    type: DELETE_TRANSACTION_FAILURE,
    payload: error,
  };
};
