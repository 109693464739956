import server from "./server";

export const getAllBankAccountsApi = async (search) => {
  const response = await server().get(`/bankAccounts${search}`);
  return response.data;
};

export const getSingleBankAccountApi = async (id) => {
  const response = await server().get(`/bankAccounts/${id}`);
  return response.data;
};

export const addBankAccountApi = async (payload) => {
  const response = await server().post(`/bankAccounts`, payload);
  return response.data;
};
export const deleteBankAccountApi = async (id) => {
  const response = await server().delete(`/bankAccounts/${id}`);
  return response.data;
};

export const editBankAccountApi = async ({ bankAccountId, data }) => {
  const response = await server().put(`/bankAccounts/${bankAccountId}`, data);
  return response.data;
};

// get all banks

export const getAllBanksApi = async () => {
  const response = await server().get(`/banks`);
  return response.data;
};

// set bankAccount as default
export const setBankAccountAsDefaultApi = async ({ bankAccountId }) => {
  const response = await server().post(
    `/bankAccounts/setDefault/${bankAccountId}`
  );
  return response.data;
};
