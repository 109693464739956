import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getCurrentUserApi,
  getUserStatusApi,
  postUserLogin,
  updateUserStatusApi,
} from "api/users";

// Login Redux States
import {
  CURRENT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER_STATUS,
} from "./actionTypes";
import {
  apiError,
  getCurrentUser,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  getUserStatusFailure,
  getUserStatusSuccess,
  loginSuccess,
  logoutUserSuccess,
  updateUserStatusFailure,
  updateUserStatusSuccess,
} from "./actions";

function* loginUser({ payload }) {
  try {
    const { data } = yield call(postUserLogin, payload);
    yield put(loginSuccess(data));
    // TODO THE DATA?.TOKEN will be corrected
    localStorage.setItem("token", data?.token);
  } catch (error) {
    console.log(error);
    yield put(apiError(error.response.data.errors[0].msg));
  }
}

function* logoutUser() {
  try {
    yield put(logoutUserSuccess());
    localStorage.removeItem("token");
  } catch (error) {
    yield put(apiError(error.response.data.errors[0].msg));
  }
}

function* currentUser() {
  try {
    const { data } = yield call(getCurrentUserApi);
    yield put(getCurrentUserSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCurrentUserFailure(error.response.data.error || error));
  }
}

function* getUserStatusSaga({ payload }) {
  try {
    const { data } = yield call(getUserStatusApi, payload);
    yield put(getUserStatusSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getUserStatusFailure(error.response.data.error || error));
  }
}

function* updateUserStatusSaga({ payload }) {
  try {
    const { data } = yield call(updateUserStatusApi, payload);
    yield put(updateUserStatusSuccess(data));
    yield put(getCurrentUser());
  } catch (error) {
    console.log(error);
    yield put(updateUserStatusFailure(error.response.data.error || error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchCurrentUser() {
  yield takeEvery(CURRENT_USER, currentUser);
}

// GET STATUS
export function* watchGetUserStatusSaga() {
  yield takeEvery(UPDATE_USER_STATUS, getUserStatusSaga);
}
// CHANGE STATUS
export function* watchUpdateUserStatusSaga() {
  yield takeEvery(UPDATE_USER_STATUS, updateUserStatusSaga);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchCurrentUser),
    fork(watchUpdateUserStatusSaga),
  ]);
}

export default authSaga;
