import {
  GET_HOME_DATA,
  GET_HOME_DATA_SUCCESS,
  GET_HOME_DATA_FAILURE,
} from "./actionTypes";

const initialState = {
  data: {},
  loading: false,
  error: "",
};

const home = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_HOME_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_HOME_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: payload,
      };
      break;
    case GET_HOME_DATA_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default home;
