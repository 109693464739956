import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getHomeDataApi } from "../../api/home";

// Login Redux States
import { GET_HOME_DATA } from "./actionTypes";
import { getHomeDataFailure, getHomeDataSuccess } from "./actions";

function* getHomeData({ payload }) {
  try {
    const { data } = yield call(getHomeDataApi, payload);
    yield put(getHomeDataSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getHomeDataFailure(error.response.data || error));
  }
}

export function* watchGetHomeData() {
  yield takeEvery(GET_HOME_DATA, getHomeData);
}

function* homeSaga() {
  yield all([fork(watchGetHomeData)]);
}

export default homeSaga;
