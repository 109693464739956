import axios from "axios";

const createServer = () => {
  const headers = {};
  const token = localStorage.getItem("token");
  if (token) headers.Authorization = `Bearer ${token}`;
  return axios.create({
    baseURL: `${process?.env?.REACT_APP_API_URL}/api`,
    headers,
  });
};

export default createServer;
