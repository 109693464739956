import {
  GET_HOME_DATA,
  GET_HOME_DATA_SUCCESS,
  GET_HOME_DATA_FAILURE,
} from "./actionTypes";

export const getHomeData = () => {
  return {
    type: GET_HOME_DATA,
  };
};

export const getHomeDataSuccess = (payload) => {
  return {
    type: GET_HOME_DATA_SUCCESS,
    payload,
  };
};

export const getHomeDataFailure = (error) => {
  return {
    type: GET_HOME_DATA_FAILURE,
    payload: error,
  };
};
