import {
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAILURE,
  GET_SINGLE_ORDER,
  GET_SINGLE_ORDER_SUCCESS,
  GET_SINGLE_ORDER_FAILURE,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  ADD_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAILURE,
} from "./actionTypes";

const initialState = {
  orders: [],
  singleOrder: {},
  metaData: {},
  loading: false,
  error: "",
};

const orders = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_ORDERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_ORDERS_SUCCESS: {
      const { orders, metadata: metaData } = payload;
      state = {
        ...state,
        loading: false,
        orders: orders,
        metaData: metaData,
      };
      break;
    }
    case GET_ALL_ORDERS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    case GET_SINGLE_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleOrder: payload.order,
        error: false,
      };
      break;
    case GET_SINGLE_ORDER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
        singleOrder: {},
      };
      break;

    case ADD_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_ORDER_SUCCESS:
      const ordersArrayAfterAddingOrder = [...state?.orders, payload.order];
      state = {
        ...state,
        loading: false,
        orders: ordersArrayAfterAddingOrder,
        error: "",
      };
      break;
    case ADD_ORDER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;
    case DELETE_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ORDER_SUCCESS:
      const ordersArrayAfterDeletingOrder = [
        ...state?.orders?.filter((order) => order?.id !== payload),
      ];
      state = {
        ...state,
        loading: false,
        orders: ordersArrayAfterDeletingOrder,
      };
      break;
    case DELETE_ORDER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default orders;
