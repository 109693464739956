import server from "./server";

export const postUserLogin = async (data) => {
  const response = await server().post("/auth/login", data);
  return response.data;
};

export const getCurrentUserApi = async (data) => {
  const response = await server().get("/auth/currentSubscriber", data);
  return response.data;
};

// change user status
export const getUserStatusApi = async ({ subscriberId }) => {
  const response = await server().get(`/subscribers/getUrl/${subscriberId}`);
  return response.data;
};
export const updateUserStatusApi = async ({ subscriberId, data }) => {
  const response = await server().put(
    `/subscribers/updateUrl/${subscriberId}`,
    data
  );
  return response.data;
};
