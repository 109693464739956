import server from "./server";

export const getAllOrdersApi = async ({ search }) => {
  const response = await server().get(`/orders${search}`);
  return response.data;
};

export const getSingleOrderApi = async (id) => {
  const response = await server().get(`/orders/${id}`);
  return response.data;
};

export const addOrderApi = async (payload) => {
  const response = await server().post(`/orders`, payload);
  return response.data;
};
export const deleteOrderApi = async (id) => {
  const response = await server().delete(`/orders/${id}`);
  return response.data;
};

export const editOrderApi = async ({ id, data }) => {
  const response = await server().put(`/orders/${id}`, data);
  return response.data;
};
