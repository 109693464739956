import { lazy } from "react";
import { v4 } from "uuid";

export const topBlocksData = [
  {
    id: v4(),
    icon: require("../src/assets/svgs/ticketsIcon.svg").default,
    title: "التحويلات",
    subtitle: "عرض التحويلات",
    url: "transactions",
  },
  {
    id: v4(),
    icon: require("../src/assets/svgs/tasksIcon.svg").default,
    title: "الطلبات",
    subtitle: "عرض الطلبات",
    url: "orders",
  },

  {
    id: v4(),
    icon: require("../src/assets/svgs/paymentIcon.svg").default,
    title: "الحسابات البنكيه",
    subtitle: "عرض الحسابات البنكيه",
    url: "bankAccounts",
  },
];

export const activeCustomersData = [
  {
    id: "1",
    url: require("../src/assets/images/customer.png").default,
    name: "محمد إبرهيم",
    subtitle: "Spent this month",
  },
  {
    id: "2",
    url: require("../src/assets/images/customer.png").default,
    name: "محمد إبرهيم",
    subtitle: "Spent this month",
  },
];

export const activeUsersData = [
  {
    id: "1",
    url: require("../src/assets/images/emp1.png").default,
    name: "سهام سامي",
    job: "Back End developer",
  },
  {
    id: "2",
    url: require("../src/assets/images/emp2.png").default,
    name: "محمد إبرهيم",
    job: "ui/ux designer",
  },
  {
    id: "3",
    url: require("../src/assets/images/emp3.png").default,
    name: "عمار ياسر",
    job: "front end developer",
  },
];

export const reportBlocksData = [
  {
    id: "1",
    title: "طلب توصيل",
    dateRange: "31/01/2020 – 06/02/2021",
    name: "طلب توصيل منتجات الي العميل ",
    body: "مجموعه منتجات مختاره للتوصيل الي المكان المحدد في الوقت المختار",
    lang: "توصيل سريع",
    cost: "2000 ريال",
    className: "card-green-bg",
  },
  {
    id: "2",
    title: "طلب توصيل",
    dateRange: "31/01/2020 – 06/02/2021",
    name: "طلب توصيل منتجات الي العميل ",
    body: "مجموعه منتجات مختاره للتوصيل الي المكان المحدد في الوقت المختار",
    lang: "توصيل سريع",
    cost: "2000 ريال",
    className: "card-red-bg",
  },
  {
    id: "3",
    title: "طلب توصيل",
    dateRange: "31/01/2020 – 06/02/2021",
    name: "طلب توصيل منتجات الي العميل ",
    body: "مجموعه منتجات مختاره للتوصيل الي المكان المحدد في الوقت المختار",
    lang: "توصيل سريع",
    cost: "2000 ريال",
    className: "card-blue-bg",
  },
  {
    id: "4",
    title: "طلب توصيل",
    dateRange: "31/01/2020 – 06/02/2021",
    name: "طلب توصيل منتجات الي العميل ",
    body: "مجموعه منتجات مختاره للتوصيل الي المكان المحدد في الوقت المختار",
    lang: "توصيل سريع",
    cost: "2000 ريال",
    className: "card-green-bg",
  },
];

export const projectsTableHead = [
  {
    name: "الأسم ",
  },
  {
    name: " الموقع",
  },
  {
    name: "نوع السائق",
  },
];
export const projectsTableHeadForReports = [
  {
    driver: " السائق",
  },
  {
    status: " الحاله",
  },
  {
    date: " التاريخ",
  },
];

export const projectsTableData = [
  {
    id: "1",
    name: "هاف ميل المحدودة (Halfmilelimited) 123",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "2",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "3",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "4",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "5",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "6",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "7",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "8",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "9",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "10",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "11",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "12",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "13",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "14",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "15",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "16",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "17",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "18",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "19",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "20",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "21",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "22",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "23",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "24",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "25",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "26",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "27",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "28",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "29",
    name: "هاف ميل المحدودة (Halfmilelimited)",
    client: "مروي سعد",
    type: "سائق مميز",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
];

export const paymentCardsData = [
  {
    id: "1",
    url: require("../src/assets/images/banks/1.png").default,
    status: true,
  },
  {
    id: "2",
    url: require("../src/assets/images/banks/2.png").default,
    status: true,
  },
  {
    id: "3",
    url: require("../src/assets/images/banks/3.png").default,
    status: true,
  },
  {
    id: "4",
    url: require("../src/assets/images/banks/4.png").default,
  },
  {
    id: "5",
    url: require("../src/assets/images/banks/5.png").default,
  },
  {
    id: "6",
    url: require("../src/assets/images/banks/6.png").default,
    status: true,
  },
];

export const ticketsTableData = [
  {
    id: "1",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "2",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "3",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "4",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "5",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "6",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "7",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "8",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "9",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "10",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "11",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "12",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "13",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "14",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "15",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "16",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "17",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "18",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "19",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "20",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
];

export const tasksTableData = [
  {
    id: "1",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "2",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "3",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "4",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "5",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "6",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "7",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "8",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "9",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "10",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "11",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "red",
  },
  {
    id: "12",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "13",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "14",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "blue",
  },
  {
    id: "15",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "16",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "17",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "18",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "19",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
  {
    id: "20",
    name: "#12542154",
    client: "مروي سعد",
    type: "متجر إلكتروني",
    showButtons: true,
    showStatus: true,
    className: "green",
  },
];

export const reportsItemsData = [
  {
    id: "1",
    path: require("../src/assets/svgs/customerIcon.svg").default,
    title: "تقارير العملاء",
    subtitle: "Spent this month",
    className: "itemTheme1",
    url: "customers-reports",
    xxl: "4",
    sm: "6",
    xs: "12",
  },
  {
    id: "2",
    path: require("../src/assets/svgs/employeeIcon.svg").default,
    title: "تقارير الموظفون",
    subtitle: "Spent this month",
    className: "itemTheme2",
    url: "customers-reports",
    xxl: "4",
    sm: "6",
    xs: "12",
  },
  {
    id: "3",
    path: require("../src/assets/svgs/paymentIcon.svg").default,
    title: "تقارير المالية",
    subtitle: "Spent this month",
    className: "itemTheme3",
    url: "customers-reports",
    xxl: "4",
    sm: "6",
    xs: "12",
  },
  {
    id: "4",
    path: require("../src/assets/svgs/projectsIcon.svg").default,
    title: "تقارير المشاريع",
    subtitle: "Spent this month",
    className: "itemTheme4",
    url: "customers-reports",
    xxl: "4",
    sm: "6",
    xs: "12",
  },
  {
    id: "5",
    path: require("../src/assets/svgs/ticketsIcon.svg").default,
    title: "تقارير التذاكر",
    subtitle: "Spent this month",
    className: "itemTheme5",
    url: "customers-reports",
    xxl: "4",
    sm: "6",
    xs: "12",
  },
  {
    id: "6",
    path: require("../src/assets/svgs/tasksIcon.svg").default,
    title: "تقارير المهام",
    subtitle: "Spent this month",
    className: "itemTheme6",
    url: "customers-reports",
    xxl: "4",
    sm: "6",
    xs: "12",
  },
];

export const customersReportsData = [
  {
    id: "1",
    path: require("../src/assets/images/pieChart.png").default,
    title: "تقرير العملاء خلال اليوم",
    subtitle: "Spent this month",
    url: "customers-reports",
  },
  {
    id: "2",
    path: require("../src/assets/images/pieChart.png").default,
    title: "تقرير عدد مدخلات المبيعات",
    subtitle: "Spent this month",
    url: "customers-reports",
  },
  {
    id: "3",
    path: require("../src/assets/images/pieChart.png").default,
    title: "تقرير عملاء التسويق",
    subtitle: "Spent this month",
    url: "customers-reports",
  },
  {
    id: "4",
    path: require("../src/assets/images/pieChart.png").default,
    title: "تقرير عملاء التسويق",
    subtitle: "Spent this month",
    url: "customers-reports",
  },
  {
    id: "5",
    path: require("../src/assets/images/pieChart.png").default,
    title: "تقرير العملاء خلال اليوم",
    subtitle: "Spent this month",
    url: "customers-reports",
  },
];

export const employeeListData = [
  {
    id: "1",
    path: require("../src/assets/images/user.gif").default,
    title: "Mohamed ibrahim",
    subtitle: "Creating UI and Research",
    url: "",
    login: "09:00 AM",
    logout: "05:00 PM",
  },
  {
    id: "2",
    path: require("../src/assets/images/user.gif").default,
    title: "Mohamed ibrahim",
    subtitle: "Creating UI and Research",
    url: "",
    login: "09:00 AM",
    logout: "05:00 PM",
  },
  {
    id: "3",
    path: require("../src/assets/images/user.gif").default,
    title: "Mohamed ibrahim",
    subtitle: "Creating UI and Research",
    url: "",
    login: "09:00 AM",
    logout: "05:00 PM",
  },
  {
    id: "4",
    path: require("../src/assets/images/user.gif").default,
    title: "Mohamed ibrahim",
    subtitle: "Creating UI and Research",
    url: "",
    login: "09:00 AM",
    logout: "05:00 PM",
  },
  {
    id: "5",
    path: require("../src/assets/images/user.gif").default,
    title: "Mohamed ibrahim",
    subtitle: "Creating UI and Research",
    url: "",
    login: "09:00 AM",
    logout: "05:00 PM",
  },
];

export const CustomersListData = [
  {
    id: "1",
    path: require("../src/assets/images/user.png").default,
    title: "Mohamed ibrahim",
    subtitle: "ecommerce website",
    type: "ecommerce",
    status: "active",
  },
  {
    id: "2",
    path: require("../src/assets/images/user.png").default,
    title: "Mohamed ibrahim",
    subtitle: "ecommerce website",
    type: "ecommerce",
    status: "away",
  },
  {
    id: "3",
    path: require("../src/assets/images/user.png").default,
    title: "Mohamed ibrahim",
    subtitle: "ecommerce website",
    type: "ecommerce",
    status: "away",
  },
  {
    id: "4",
    path: require("../src/assets/images/user.png").default,
    title: "Mohamed ibrahim",
    subtitle: "ecommerce website",
    type: "ecommerce",
    status: "active",
  },
  {
    id: "5",
    path: require("../src/assets/images/user.png").default,
    title: "Mohamed ibrahim",
    subtitle: "ecommerce website",
    type: "ecommerce",
    status: false,
  },
];

export const permissionsItemsData = [
  {
    id: "1",
    path: require("../src/assets/svgs/groupIcon.svg").default,
    title: "انشاء مجموعه جديدة",
    subtitle: "Spent this month",
    className: "itemTheme7",
    url: "customers-reports",
    xxl: "7",
    lg: "10",
    md: "12",
  },
  {
    id: "2",
    path: require("../src/assets/svgs/customerIcon.svg").default,
    title: "مجموعات المستخدمين",
    subtitle: "Spent this month",
    className: "itemTheme8",
    url: "customers-reports",
    xxl: "7",
    lg: "10",
    md: "12",
  },
];

export const permissionsItemsBigData = [
  {
    id: "1",
    path: require("../src/assets/svgs/groupIcon.svg").default,
    title: "صلاحيات الموظفين",
    subtitle: "Spent this month",
    className: "bigCard itemGray",
    url: "customers-reports",
    xxl: "7",
    lg: "10",
    md: "12",
  },
  {
    id: "2",
    path: require("../src/assets/svgs/customerIcon.svg").default,
    title: "صلاحيات العملاء",
    subtitle: "Spent this month",
    className: "bigCard itemGray",
    url: "customers-reports",
    xxl: "7",
    lg: "10",
    md: "12",
  },
];

export const sidebarData = [
  {
    id: v4(),
    to: "/",
    text: "home",
    svg: (
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 18.9103H2C0.89543 18.9103 0 17.9957 0 16.8675V2.56837C0 1.4402 0.89543 0.525635 2 0.525635H18C19.1046 0.525635 20 1.4402 20 2.56837V16.8675C20 17.9957 19.1046 18.9103 18 18.9103ZM2 4.6111V16.8675H18V4.6111H2ZM16 14.8248H10V12.782H16V14.8248ZM5.414 14.8248L4 13.3806L6.293 11.0396L4 8.69658L5.414 7.25236L9.12 11.0386L5.415 14.8248H5.414Z"
          fill="#A3AED0"
        />
      </svg>
    ),
  },

  {
    id: v4(),
    to: "/transactions",
    text: "transactions",
    svg: (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16ZM2 8V14H18V8H2ZM2 2V4H18V2H2ZM11 12H4V10H11V12Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    children: [],
  },
  {
    id: v4(),
    to: "/orders",
    text: "orders",
    svg: (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21.8088L3 14.6592L4.62 13.3723L11.99 19.2248L19.37 13.3621L21 14.6592L12 21.8088ZM12 17.4476L4.63 11.5951L3 10.298L12 3.14844L21 10.298L19.36 11.5951L12 17.4476Z"
          fill="#A3AED0"
        />
      </svg>
    ),
    children: [],
  },

  {
    id: v4(),
    to: "/bankAccounts",
    text: "bank-accounts",
    svg: (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 21.9322H5C3.89543 21.9322 3 21.0177 3 19.8895V5.59034C3 4.46217 3.89543 3.54761 5 3.54761H19C20.1046 3.54761 21 4.46217 21 5.59034V19.8895C21 21.0177 20.1046 21.9322 19 21.9322ZM5 5.59034V19.8895H19V5.59034H5ZM17 17.8468H15V10.6972H17V17.8468ZM13 17.8468H11V7.63308H13V17.8468ZM9 17.8468H7V12.7399H9V17.8468Z"
          fill="#A3AED0"
        />
      </svg>
    ),
  },
  {
    id: v4(),
    to: "/subscriberStatus",
    text: "connection-details",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#A3AED0"
        class="bi bi-bounding-box-circles"
        viewBox="0 0 18 18"
      >
        <path d="M2 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM0 2a2 2 0 0 1 3.937-.5h8.126A2 2 0 1 1 14.5 3.937v8.126a2 2 0 1 1-2.437 2.437H3.937A2 2 0 1 1 1.5 12.063V3.937A2 2 0 0 1 0 2zm2.5 1.937v8.126c.703.18 1.256.734 1.437 1.437h8.126a2.004 2.004 0 0 1 1.437-1.437V3.937A2.004 2.004 0 0 1 12.063 2.5H3.937A2.004 2.004 0 0 1 2.5 3.937zM14 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM2 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
      </svg>
    ),
  },
];

// fututre refacrot i could assign a function to the start of the path

export const adminTypes = [
  {
    id: v4(),
    type: "admin",
  },
  {
    id: v4(),
    type: "driver",
  },
];
export const paymentTypes = [
  {
    id: v4(),
    type: "debit",
    placeholder: "مدين",
  },
  {
    id: v4(),
    type: "credit",
    placeholder: "دائن",
  },
];

export const orderStatus = [
  {
    id: v4(),
    type: "manual",
    placeholder: "نظام",
  },
  {
    id: v4(),
    type: "transfer",
    placeholder: "تحويل بنكي",
  },
];

export const operationTypes = [
  {
    id: v4(),
    type: "manual",
    placeholder: "يدوي",
  },
  {
    id: v4(),
    type: "transfer",
    placeholder: "تحويل بنكي",
  },
];

export const driverStatus = [
  {
    id: v4(),
    placeholder: "معلق",
    type: "pending",
  },
  {
    id: v4(),
    placeholder: "تحت المراجعه",
    type: "underReview",
  },
  {
    id: v4(),
    placeholder: "خطأ في المراجعه",
    type: "reviewError",
  },
  {
    id: v4(),
    placeholder: "موافق عليه",
    type: "approved",
  },
  {
    id: v4(),
    placeholder: "محظور",
    type: "blocked",
  },
];
export const subscriberStatus = [
  {
    id: v4(),
    placeholder: "معلق",
    type: "pending",
  },
  {
    id: v4(),
    placeholder: "موافق عليه",
    type: "approved",
  },
  {
    id: v4(),
    placeholder: "محظور",
    type: "blocked",
  },
];

export const transactionErrorMessage = "لايوجد حسابات بنكيه لاتمام التحويل";
