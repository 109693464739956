import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addBankAccountApi,
  deleteBankAccountApi,
  editBankAccountApi,
  getAllBankAccountsApi,
  getSingleBankAccountApi,
  getAllBanksApi,
  setBankAccountAsDefaultApi,
} from "api/bankAccounts";

import {
  ADD_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
  GET_ALL_BANK_ACCOUNTS,
  GET_SINGLE_BANK_ACCOUNT,
  GET_ALL_BANKS,
  SET_BANK_ACCOUNT_AS_DEFAULT,
} from "./actionTypes";
import {
  getAllBankAccountsSuccess,
  getAllBankAccountsFailure,
  getSingleBankAccountSuccess,
  getSingleBankAccountFailure,
  deleteBankAccountSuccess,
  deleteBankAccountFailure,
  editBankAccountSuccess,
  editBankAccountFailure,
  addBankAccountSuccess,
  addBankAccountFailure,
  getAllBanksSuccess,
  getAllBanksFailure,
  setBankAccountAsDefaultSuccess,
  setBankAccountAsDefaultFailure,
} from "./actions";
import { createToaster } from "../toastify/actions";

const errorMessage = (error) =>
  error.response.data.errors[0].msg || error.response.data.errors || "error";

function* getAllBankAccounts({ payload }) {
  try {
    const { data } = yield call(getAllBankAccountsApi, payload);
    yield put(getAllBankAccountsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllBankAccountsFailure(error.response.data || error));
  }
}

function* getSingleBankAccount({ payload }) {
  try {
    const { data } = yield call(getSingleBankAccountApi, payload);
    yield put(getSingleBankAccountSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleBankAccountFailure(error.response.data || error));
  }
}

function* addBankAccount({ payload }) {
  const id = Math.random();

  try {
    const { data } = yield call(addBankAccountApi, payload.data);
    yield put(addBankAccountSuccess(data));

    yield payload.navigate("/bankAccounts");
  } catch (error) {
    console.log(error);
    yield put(addBankAccountFailure(error.response.data.message || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* deleteBankAccount({ payload }) {
  const id = Math.random();

  try {
    yield call(deleteBankAccountApi, payload);
    yield put(deleteBankAccountSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteBankAccountFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

function* editBankAccount({ payload }) {
  const id = Math.random();

  try {
    yield call(editBankAccountApi, payload);
    yield put(editBankAccountSuccess(payload));

    yield payload.navigate("/bankAccounts");
  } catch (error) {
    console.log(error);
    yield put(editBankAccountFailure(error.response.data || error));
    yield put(
      createToaster({
        type: "error",
        message: errorMessage(error),
        toasterID: id,
      })
    );
  }
}

// banks

function* getAllBanks({ payload }) {
  try {
    const { data } = yield call(getAllBanksApi, payload);
    yield put(getAllBanksSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAllBanksFailure(error.response.data || error));
  }
}

// set bank account as default

function* setBankAccountAsDefaultSaga({ payload }) {
  try {
    const { data } = yield call(setBankAccountAsDefaultApi, payload);
    yield put(setBankAccountAsDefaultSuccess(data));
    yield payload.navigate("/bankAccounts");
  } catch (error) {
    console.log(error);
    yield put(setBankAccountAsDefaultFailure(error.response.data || error));
  }
}

export function* watchGetAllBankAccounts() {
  yield takeEvery(GET_ALL_BANK_ACCOUNTS, getAllBankAccounts);
}
export function* watchGetSingleBankAccount() {
  yield takeEvery(GET_SINGLE_BANK_ACCOUNT, getSingleBankAccount);
}
export function* watchDeleteBankAccount() {
  yield takeEvery(DELETE_BANK_ACCOUNT, deleteBankAccount);
}
export function* watchAddBankAccount() {
  yield takeEvery(ADD_BANK_ACCOUNT, addBankAccount);
}
export function* watchEditBankAccount() {
  yield takeEvery(EDIT_BANK_ACCOUNT, editBankAccount);
}

// all banks
export function* watchGetAllBanks() {
  yield takeEvery(GET_ALL_BANKS, getAllBanks);
}

// set bank account as default
export function* watchSetBankAccountAsDefault() {
  yield takeEvery(SET_BANK_ACCOUNT_AS_DEFAULT, setBankAccountAsDefaultSaga);
}

function* bankAccountsSaga() {
  yield all([fork(watchGetAllBankAccounts)]);
  yield all([fork(watchAddBankAccount)]);
  yield all([fork(watchEditBankAccount)]);
  yield all([fork(watchDeleteBankAccount)]);
  yield all([fork(watchGetSingleBankAccount)]);

  yield all([fork(watchGetAllBanks)]);

  yield all([fork(watchSetBankAccountAsDefault)]);
}

export default bankAccountsSaga;
