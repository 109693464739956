export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const CURRENT_USER = "CURRENT_USER";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAILURE = "CURRENT_USER_FAILURE";

export const API_ERROR = "LOGIN_API_ERROR";

// user Status
export const GET_USER_STATUS = "GET_USER_STATUS";
export const GET_USER_STATUS_SUCCESS = "GET_USER_STATUS_SUCCESS";
export const GET_USER_STATUS_FAILURE = "GET_USER_STATUS_FAILURE";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";
