import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";
import { getCurrentUser } from "store/actions";
import Loader from "./components/shared/Loader";

import appRoutes from "constant/routes";
import { v4 } from "uuid";

const App = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  //selectors
  const { isLoggedIn, loading } = useSelector((state) => state?.authentication);

  useEffect(() => {
    if (token) dispatch(getCurrentUser());
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              {appRoutes?.map(({ path, Element }) => {
                return (
                  <Route
                    key={v4()}
                    path={path}
                    element={
                      <Suspense fallback={<Loader />}>
                        <AuthRoute>
                          <Element />
                        </AuthRoute>
                      </Suspense>
                    }
                  />
                );
              })}
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login />
                </GuestRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null && loading === false) return RenderApp();
  return <Loader />;
};
export default App;
